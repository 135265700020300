<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <div v-else class="blog">
      <!-- blog hero section -->
      <section class="blog__hero">
        <b-container>
          <b-row>
            <b-col cols="12" md="6">
              <h1 v-html="$prismic.asHtml(this.primary_title)" class="blog__title"></h1>
              <p v-html="$prismic.asHtml(this.primary_text)" class="main-text main-text--semi"></p>
            </b-col>
            <b-col class="text-center" cols="12" md="6">
              <img v-lazy="this.primary_image.url" class="img-fluid blog__image" :alt="this.primary_image.alt" />
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row>
            <b-col cols="12">
              <h2 v-html="$prismic.asHtml(this.post_list_heading)" class="main-title" id="postListTitle"></h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="9">
              <!-- post list section -->
              <PostList />
            </b-col>
            <b-col cols="12" lg="3">
              <!-- twitter widget -->
              <TwitterWidget />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- aside section -->
      <AsideComponent />
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'BlogPage',
  components: {
    Spinner,
    PostList: () => import('@/views/BlogPage/components/PostList/PostList.vue'),
    TwitterWidget: () => import('@/views/BlogPage/components/TwitterWidget/TwitterWidget.vue'),
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      cmsContentPending: false,
      primary_title: null,
      primary_text: null,
      primary_image: null,
      post_list_heading: null
    }
  },
  methods: {
    async getBlogPageContent () {
      await this.$prismic.client.getSingle('blogpage')
        .then((document) => {
          // primary/hero section
          this.primary_title = document.data.primary_title
          this.primary_text = document.data.primary_text
          this.primary_image = document.data.primary_image_url
          this.post_list_heading = document.data.post_list_heading
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getBlogPageContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "./BlogPage.scss";
</style>
